const es = {
  'yes': 'sí',
  'no': 'no',

  start: 'Incio',
  end: 'Fin',

  'hour': 'hora',
  'day': 'día',
  'time-left': `
    ¡Te queda{amount, plural, =0 {n} one {} other {n}} {amount}
    {unit}{amount, plural, =0 {s} one {} other {s}}!
  `,

  'page-not-found': 'Oh no! Hemos perdido esta página',
  'not-available-in-lang': 'Este contenido no está disponible actualmente en español.',

  'value-missing-validation-error': 'Campo requerido',
  'type-missmatch-validation-error': 'Formato incorrecto',
  'invalid-number-validation-error': 'Número no es válido',
  'number-must-be-gt-zero': 'Número debe ser mayor que cero',

  'user/first-name': 'Nombre',
  'user/last-name': 'Apellidos',
  'user/lang': 'Idioma preferido',
  'user/identifies-as-female': '¿Te identificas como mujer?',
  'user/date-of-birth': '¿Cuál es tu fecha de nacimiento?',
  'user/country': '¿En qué país vives?',
  'user/mobile-number': '¿Cuál es tu número de celular?',
  'user/mobile-number-helper-text': `
    Si tienes cuenta de WhatsApp, usa ese número para que sea más fácil ponernos
    en contacto
  `,
  'user/github': '¿Cuál es tu usuario en GitHub?',
  'user/github-helper-text': `
    GitHub es una plataforma muy usado por todo tipo de desarrolladoras.
    Dinos cuál es tu usuario en GitHub para poder mostrar tu avatar
    (foto).
  `,

  'program-1': 'Bootcamp de desarrollo web',
  'program-2': 'Extensión FullStack',
  'program-3': 'Bootcamp de diseño UX',
  'program-4': 'Bootcamp de análisis de datos',
  'program-5': 'Upskilling',

  'program-web-dev': 'Desarrollo web',
  'program-ux': 'Diseño de experiencia de usuario',
  'program-data': 'Análisis de datos',

  'projects/variants': 'Variantes de implementación',
};

export default es;
