import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useApp, gTagSet, Loading } from '@laboratoria/sdk-react';
import RoutesWithIntl from './RoutesWithIntl';

const App = () => {
  const { auth } = useApp();
  const [defaultLang, setDefaultLang] = useState();

  useEffect(() => {
    if (typeof auth.user === 'undefined' || auth.user === null) {
      return;
    }

    // Set UID in GA4 session and add to future GA4 events.
    // Previous session events will be associated with the UID.
    gTagSet('user_id', auth.user.uid);
  }, [auth.user]);

  useEffect(() => {
    if (auth.user && !auth.profile) {
      return;
    }

    const profileLang = auth.user?.lang;
    setDefaultLang(
      ['es', 'pt'].includes(profileLang)
        ? profileLang
        : navigator.language.split('-')[0] === 'pt'
          ? 'pt'
          : 'es'
    )
  }, [auth]);

  if (defaultLang === undefined || auth.user === undefined) {
    return <Loading />;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={`/${defaultLang}/${window.location.search}`} />}
        />
        <Route
          path="/:lang/*"
          element={<RoutesWithIntl defaultLang={defaultLang} user={auth.user} />}
        />
      </Routes>
    </Router>
  );
};


export default App;
