import { lazy, Suspense } from 'react';
import { Outlet, Navigate, Routes, Route, useLocation, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Loading, ScrollRestoration } from '@laboratoria/sdk-react';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import { IntlProvider } from '../../intl';

const Account = lazy(() => import('../Account'));
const Application = lazy(() => import('../Application'));
const Apply = lazy(() => import('../Apply'));
const Auth = lazy(() => import('../Auth'));
const Cohort = lazy(() => import('../Cohort'));
const Evaluation = lazy(() => import('../Evaluation'));
const Home = lazy(() => import('../Home'));
const StudentProject = lazy(() => import('../StudentProject'));
const TopBar = lazy(() => import('../TopBar'));
const UserForm = lazy(() => import('../UserForm'));
const Program = lazy(() => import('../Program'));
const Project = lazy(() => import('../Project'));
const Projects = lazy(() => import('../Projects'));
const Topic = lazy(() => import('../Topic'));
const Topics = lazy(() => import('../Topics'));

const NotFound = () => {
  return (
    <Container>
      <Alert severity="error">
        <FormattedMessage id="page-not-found" />
      </Alert>
    </Container>
  );
};

const ProtectedRoute = ({ lang, user, children }) => {
  const { pathname, search } = useLocation();
  if (!user) {
    return <Navigate to={`/${lang}/auth/signin?r=${pathname}${search}`} replace />;
  }
  return children || <Outlet />;
};

const RoutesWithIntl = ({ defaultLang, user }) => {
  const location = useLocation();
  const { lang } = useParams();

  if (!['es', 'pt'].includes(lang)) {
    return (
      <Navigate to={`/${defaultLang}${location.pathname}${location.search}`} />
    );
  }

  return (
    <Suspense fallback={<Loading />}>
      <IntlProvider lang={lang}>
        <ScrollRestoration />
        <TopBar lang={lang} />
        <Routes>
          <Route element={<ProtectedRoute lang={lang} user={user} />}>
            <Route path="/cohorts/:id" element={<Cohort lang={lang} />} />
            <Route path="/student-projects/:id" element={<StudentProject lang={lang} />} />
            <Route path="/student-projects/:id/evaluation" element={<Evaluation lang={lang} />} />
            <Route path="/account" element={<Account lang={lang} />} />
            <Route path="/account/_edit" element={<UserForm lang={lang} />} />
            <Route path="/applications/:id" element={<Application lang={lang} />} />
            <Route path="/applications/:id/*" element={<Application lang={lang} />} />
            <Route path="/apply/*" element={<Apply lang={lang} />} />
          </Route>

          <Route path="/auth/*" element={<Auth lang={lang} />} />
          <Route path="/:track" element={<Program lang={lang} />} />
          <Route path="/:track/projects" element={<Projects lang={lang} />} />
          <Route path="/projects/:slug" element={<Project lang={lang} />} />
          <Route path="/:track/topics" element={<Topics lang={lang} />} />
          <Route path="/topics/:slug/*" element={<Topic lang={lang} />} />
          <Route path="/" element={<Home lang={lang} />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </IntlProvider>
    </Suspense>
  );
};

export default RoutesWithIntl;
