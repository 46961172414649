const pt = {
  'yes': 'sim',
  'no': 'não',

  start: 'Início',
  end: 'Fim',

  'hour': 'hora',
  'day': 'dia',
  'time-left': `
    Você tem {amount}
    {unit}{amount, plural, =0 {s} one {} other {s}}
    restante{amount, plural, =0 {s} one {} other {s}}!
  `,

  'page-not-found': 'Oh não! nós perdemos esta página',
  'not-available-in-lang': 'Este conteúdo não está disponível atualmente em português.',

  'value-missing-validation-error': 'Campo obrigatório',
  'type-missmatch-validation-error': 'Formato incorreto',
  'invalid-number-validation-error': 'Número é inválido',
  'number-must-be-gt-zero': 'O número deve ser maior que zero',

  'user/first-name': 'Primeiro nome',
  'user/last-name': 'Sobrenome',
  'user/lang': 'Língua preferida',
  'user/identifies-as-female': 'Você se identifica como mulher?',
  'user/date-of-birth': 'Qual é a data do seu nascimento?',
  'user/country': 'Em que país você mora?',
  'user/mobile-number': 'Qual é o seu número de celular?',
  'user/mobile-number-helper-text': `
    Se você tiver uma conta no WhatsApp, use esse número para facilitar o
    contato
  `,
  'user/github': 'Qual é o seu nome de usuário no GitHub?',
  'user/github-helper-text': `
    O GitHub é uma plataforma amplamente utilizada por todos os tipos de
    desenvolvedores. Diga-nos qual é o seu nome de usuário no GitHub para que
    possamos exibir seu avatar (foto).
  `,

  'program-1': 'Bootcamp de desenvolvimento web',
  'program-2': 'Extensão FullStack',
  'program-3': 'Bootcamp de desenho UX',
  'program-4': 'Bootcamps de análise de dados',
  'program-5': 'Upskilling',

  'program-web-dev': 'Desenvolvimento web',
  'program-ux': 'Design de experiência do usuário',
  'program-data': 'Analise de dados',

  'projects/variants': 'Variantes de implementação',
};

export default pt;
